import './App.css';
import Agapes from "./Pages/Agapes"

function App() {
  return (
    <div className="App">
    <Agapes />
    </div>
  );
}

export default App;
