import React, { useState, useEffect, Fragment } from "react";

const Agapes = () => {
    const [data,setData] = useState([]);
    const [error, setError] = useState(null);
    useEffect(() => {
        //fetch("http://localhost/agapes-ssdomaine/agapes/backend/src/ff.php")
      fetch("./backend/src/ff.php")
          .then(response => {
            if (response.ok === true) return response.json();
            else throw new Error(`Erreur HTTP => ${response.status}`);
          })
          .then(data => setData(data))
          .catch(err => setError(err.message));
      }, []);
      
       if (error) {
        return <div>Une erreur est survenue : {error}</div>;
      } 
      
      if (data === null) {
        return <div>Chargement en cours...</div>;
      }
  return (
    <Fragment>
    <head>
    <meta name="robots" content="noindex, nofollow" />
  </head>
  <body>
    <div className="Agapes">
      <h1>Agapes fraternels</h1>
      <div className="agapes-container">
        <form action="./backend/src/agapes.php" method="post">
          <fieldset>
            <legend>
              Entre ton nom puis coches si présent enfin tu peux valider
            </legend>
            <div>
              <div>
                <label>Nom</label>
                <select name="f">
                    <option selected="selected" disabled>Choisir ....</option>
                {data.map((data => <option key={data.ID} value={data.ID}>{data.NOM} {data.PRENOM}</option>))}
                </select>
              </div>
              <label>Tenue</label> <input type="checkbox" name="t"/>
            </div>
            <div>
              <label>Agapes</label> <input type="checkbox" name="a" />
            </div>
            <input type="submit" value="Valider" />
          </fieldset>
        </form>
      </div>
    </div>
    </body>
    </Fragment>
  );
};

export default Agapes;
